import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DiscoveryCategory } from '@internal/backstage-plugin-discovery-plugin';

export interface DiscoveryState {
  content: DiscoveryCategory[];
  isLoading: boolean;
  error: string | null;
  fetched: boolean;
}

const initialState: DiscoveryState = {
  content: [],
  isLoading: false,
  error: null,
  fetched: false,
};

const discoverySlice = createSlice({
  name: 'discovery',
  initialState,
  reducers: {
    startLoading: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    updateDiscovery: (state, action: PayloadAction<Partial<DiscoveryState>>) => {
      Object.assign(state, action.payload);
    },
    resetDiscovery: () => initialState,
  },
});

export const { startLoading, updateDiscovery, resetDiscovery } = discoverySlice.actions;
export default discoverySlice.reducer;
