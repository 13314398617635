import { InfoCard } from '@backstage/core-components';

import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import { styled } from '@mui/material/styles';

import ReactPlayer from "react-player";

export const CardSubtitle = styled(Typography)(({ theme }) => ({
  textTransform: 'uppercase',
  color: theme.palette.text.secondary,
  fontSize: '12px',
  paddingBottom: '12px',
}));

export const CardListItem = styled(Typography)({
  fontSize: '13px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});

export const CardListItemName = styled('span')({
  margin: '0 10px',
  flex: '1 1 auto',
  textAlign: 'left',
  wordBreak: 'break-word',
});

export const CardListItemDate = styled('span')({
  fontWeight: 600,
  fontSize: '12px'
})

export const CardDivider = styled(Divider)({
  margin: '16px 0',
});

export const HomePageContainer = styled(Grid)({
  margin: '0',
  width: '100%',
});

export const HomePageHeadingTitle = styled('h1')(({ theme }) => ({
  color: theme.palette.text.primary,
  fontSize: '36px',
  fontWeight: 600,
  paddingLeft: '20px',
}));

export const HomePageHeadingImage = styled('img')({
  placeSelf: 'center',
});

export const StyledInfoCard = styled(InfoCard)({
  padding: '20px',
  width: '100%',
});

export const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
  },
  width: '220px',
  textAlign: 'center',
  fontSize: '13px',
}));

export const StyledH4 = styled('h3')({
  margin: '2px 0',
});

export const StyledParagraph = styled('p')({
  fontSize: '20px',
});

export const StyledReactPlayer = styled(ReactPlayer)({
  position: 'absolute',
  boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.2)',
});

export const StyledInlineButton = styled(Button)({
  fontSize: '16px',
  paddingTop: '4px',
});

export const StyledCallToAction = styled(Button)({
  fontSize: '18px',
  padding: '0 8px',
  margin: 'auto 8px',
  height: '40px',
});
