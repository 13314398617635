import React from 'react';

import { styled } from '@mui/material/styles';

import Link from '@mui/material/Link';
import Divider from '@mui/material/Divider';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';
import ListItemText from '@mui/material/ListItemText';

const StyledContent = styled(CardContent)(({ theme }) => ({
  height: '100%',
  padding: theme.spacing(2),
}));

const StyledList = styled(List)(({ theme }) => ({
  paddingLeft: theme.spacing(2),
}));

const StyledListItem = styled(ListItem)(({ theme }) => ({
  display: 'list-item',
  listStyleType: 'disc',
  paddingLeft: theme.spacing(1),
}));

const Description = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

export const DescriptionNovoAccessCard: React.FC = () => (
  <Card>
    <StyledContent>
      <Description variant="body2">
        The <b>novoAccess Plugin</b> is designed to simplify the management of access permissions within <b>Novo Nordisk</b>.
        This powerful tool enables users to:
      </Description>
      <StyledList>
        <StyledListItem>
          <ListItemText
            primary="Specify Required Accesses"
            secondary="Clearly define the accesses needed for each entity, ensuring that all necessary permissions are accounted for."
          />
        </StyledListItem>
        <StyledListItem>
          <ListItemText
            primary="Apply for Multiple Permissions in One Go"
            secondary="Save time by requesting access to various platforms - like Azure DevOps, Figma, and AWS - simultaneously, rather than applying one by one."
          />
        </StyledListItem>
        <StyledListItem>
          <ListItemText
            primary="Streamline Onboarding"
            secondary="Effortlessly onboard new team members by providing them with prompt access to all required tools and resources from the start."
          />
        </StyledListItem>
      </StyledList>
      <Divider sx={{ mb: 2 }} />
      <Link
        href="/docs/default/system/developer-portal/user_guide/novoaccess-plugin/"
        target="_blank"
        rel="noopener"
      >
        Learn more about the novoAccess Plugin
      </Link>
    </StyledContent>
  </Card>
);
