import React from 'react';

import { useEntity } from '@backstage/plugin-catalog-react';
import { EmptyState } from '@backstage/core-components';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import { styled } from '@mui/material/styles';

const CodeSnippetBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  overflow: 'auto',
}));

export const GithubInsightsNoAnnotation: React.FC = () => {
  const { entity } = useEntity();
  const kind = entity?.kind || 'N/A';
  const type = entity?.spec?.type || 'N/A';

  const codeSnippet =
    `
      apiVersion: backstage.io/v1alpha1
      kind: ${kind}
      metadata:
        name: example
        annotations:
          github.com/project-slug: <<example: innersource-nn/midgard>>
      spec:
        type: ${type}
        lifecycle: production
        owner: user:guest
    `;

  return (
    <EmptyState
      missing="field"
      title="Missing GitHub Annotation"
      description={
        <>
          <Typography variant="body1" sx={{ mt: 1 }}>
            The required GitHub annotation <code>github.com/project-slug</code> is missing.
          </Typography>
          <Typography variant="body1" sx={{ mt: 1 }}>
            To enable <b>GitHub Insights</b>, add this annotation to your YAML.
          </Typography>
          <CodeSnippetBox component="pre">
            {codeSnippet}
          </CodeSnippetBox>
          <Typography variant="body2" sx={{ mt: 2 }}>
            For detailed instructions, see the{' '}
            <Link
              href="/docs/default/system/developer-portal/user_guide/quick-onboard-to-software-catalog/"
              target="_blank"
              rel="noopener"
            >
              Quick Onboard to Software Catalog guide
            </Link>.
          </Typography>
        </>
      }
    />
  );
};
