import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GithubPullRequestOrIssue } from '@internal/backstage-plugin-homepage-common';

export interface PullRequestsState {
  openPullRequests: GithubPullRequestOrIssue[];
  isLoading: boolean;
  fetched: boolean;
  error?: any;
}

const initialState: PullRequestsState = {
  openPullRequests: [],
  isLoading: false,
  fetched: false,
  error: undefined,
};

const pullRequestsSlice = createSlice({
  name: 'pullRequests',
  initialState,
  reducers: {
    startLoadingPullRequests(state) {
      state.isLoading = true;
      state.fetched = false;
      state.error = undefined;
    },
    updatePullRequests(
      state,
      action: PayloadAction<
        Partial<PullRequestsState> & { data?: GithubPullRequestOrIssue[] }
      >
    ) {
      const { data, isLoading, fetched, error } = action.payload;
      if (data) {
        state.openPullRequests = data;
      }
      if (typeof isLoading !== 'undefined') {
        state.isLoading = isLoading;
      }
      if (typeof fetched !== 'undefined') {
        state.fetched = fetched;
      }
      if (error) {
        state.error = error;
      }
    },
  },
});

export const { startLoadingPullRequests, updatePullRequests } = pullRequestsSlice.actions;
export default pullRequestsSlice.reducer;
