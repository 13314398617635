import React from "react";

import { EntityLayout, EntitySwitch } from "@backstage/plugin-catalog";
import {
  EntityGroupProfileCard,
  EntityOwnershipCard,
  EntityMembersListCard
} from "@backstage/plugin-org";

import Grid from "@mui/material/Grid";

import { EntityGithubInsightsReadmeCard, isGithubInsightsAvailable } from "@roadiehq/backstage-plugin-github-insights";
import { FeatureFlagged } from "@backstage/core-app-api";

import { entityWarningContent } from "../content/EntityWarningContent";
import { FeatureFlagValue } from "../../../../utils/featureFlags";
import { novoAccessEntityContent } from "../content/NovoAccessEntityContent";

export const groupPage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      <Grid container spacing={3}>
        {entityWarningContent}
        <Grid container item xs={12} >
          <Grid container item md={6} xs={12} alignContent="flex-start">
            <Grid item xs={12}>
              <EntityGroupProfileCard />
            </Grid>
            <Grid item xs={12}>
              <EntityMembersListCard showAggregateMembersToggle />
            </Grid>
          </Grid>
          <Grid container item md={6} xs={12} alignContent="flex-start">
            <Grid item xs={12}>
              <EntityOwnershipCard />
            </Grid>
            <EntitySwitch>
              <EntitySwitch.Case if={isGithubInsightsAvailable}>
                <Grid item xs={12}>
                  <EntityGithubInsightsReadmeCard maxHeight={350} />
                </Grid>
              </EntitySwitch.Case>
            </EntitySwitch>
          </Grid>
        </Grid>
      </Grid>
    </EntityLayout.Route>
    <FeatureFlagged with={FeatureFlagValue.SHOW_NOVOACCESS_CARD}>
      <EntityLayout.Route path="/novoaccess" title="novoAccess">
        {novoAccessEntityContent}
      </EntityLayout.Route>
    </FeatureFlagged>
  </EntityLayout>
);
