import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useApi, alertApiRef, configApiRef } from '@backstage/core-plugin-api';
import { Header } from '@backstage/core-components';

import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import ErrorOutline from '@mui/icons-material/ErrorOutline';
import { styled } from '@mui/material/styles';

const StyledCard = styled(Card)(({ theme }) => ({
  position: 'relative',
  alignItems: 'center',
  padding: '10px',
  margin: '0 25%',
  backgroundColor: theme.palette.background.paper,
  boxSizing: 'border-box',
}));

const ErrorText = styled(Typography)(({ theme }) => ({
  color: theme.palette.errorText,
  fontWeight: 'bold',
}));

const InfoText = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
}));

const StyledListItem = styled(ListItem)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
}));

const StyledButton = styled(Button)(({ theme }) => ({
  padding: '10px 20px',
  backgroundColor: theme.palette.primary.main,
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
  }
}));

export const MidgardOnboard = () => {
  const configApi = useApi(configApiRef);
  const alertApi = useApi(alertApiRef);

  const navigate = useNavigate();
  const location = useLocation();
  const url = location.pathname.split('/midgard-onboard/')[1];

  const [loading, setLoading] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [serverError, setServerError] = useState<string | null>(null);

  const handleClick = async () => {
    setLoading(true);

    try {
      const response = await fetch(`${configApi.getOptionalString('backend.baseUrl')}/api/envinfo/midgard-onboard?url=${url === null ? '' : url}`);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      await response.json();
      if (response.status === 200) {
        setTimeout(() => {
          setDialogOpen(true);
        }, 2000);
      } else {
        throw new Error('Failed to load entities to Developer Portal (Midgard)');
      }
    } catch (error: any) {
      setServerError(error.message);
      alertApi.post({ message: error.message, severity: 'error' });
    } finally {
      setLoading(false);
    }
  };
  return (
    <Grid container direction="column">
      <Dialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
      >
        <DialogTitle>Register Successful</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Your application has been successfully registered to Developer Portal.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <StyledButton onClick={() => { setDialogOpen(false); navigate('/catalog'); }}>
            OK
          </StyledButton>
        </DialogActions>
      </Dialog>
      {serverError && (
        <Dialog
          open={!!serverError}
          onClose={() => setServerError(null)}
        >
          <DialogTitle>Error in registering repository!</DialogTitle>
          <DialogContent>
            <ErrorText variant="h6" align="center">
              It seems that the register was not successful due to:
            </ErrorText>
            <List>
              <StyledListItem>
                <ListItemIcon>
                  <ErrorOutline color="error" />
                </ListItemIcon>
                <InfoText variant="body1">
                  Potential issues with your catalog-info.yaml file.
                </InfoText>
              </StyledListItem>
              <StyledListItem>
                <ListItemIcon>
                  <ErrorOutline color="error" />
                </ListItemIcon>
                <InfoText variant="body1">
                  Your project being already registered.
                </InfoText>
              </StyledListItem>
              <StyledListItem>
                <ListItemIcon>
                  <ErrorOutline color="error" />
                </ListItemIcon>
                <InfoText variant="body1">
                  The catalog-info.yaml file not being in the main branch.
                </InfoText>
              </StyledListItem>
            </List>
            <Typography variant="body1">
              In case you are not able to address these issues, it's advisable to contact the <b>Developer Portal (Midgard)</b> team for further assistance and support.
              <br /><br />
              <b>Email:</b> <a href="mailto:f1afd289.novonordisk.onmicrosoft.com@emea.teams.ms">f1afd289.novonordisk.onmicrosoft.com@emea.teams.ms</a>
            </Typography>
          </DialogContent>
          <DialogActions>
            <StyledButton onClick={() => setServerError(null)}>
              Close
            </StyledButton>
          </DialogActions>
        </Dialog>
      )}

      <Grid item>
        <Header title="Onboarding to Developer Portal" />
      </Grid>
      <Grid item>
        <StyledCard>
          <Typography variant="h3" align="center" padding={2}>
            Registering your application in Developer Portal
          </Typography>
          <Typography variant="body1" align="center" padding={2}>
            Your repository:
            <br />
            <Link href={url} color="secondary">
              {url}
            </Link>
          </Typography>
          <Typography variant="h5" align="center">
            What will happen now?
          </Typography>
          <Grid container item xs={12} justifyContent='center' padding={2}>
            <List>
              <ListItem>
                <ListItemIcon>
                  <Checkbox edge="start" disableRipple checked disabled />
                </ListItemIcon>
                <ListItemText primary="Your application will appear on the Developer Portal, making it accessible to whole Novo Nordisk." />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <Checkbox edge="start" disableRipple checked disabled />
                </ListItemIcon>
                <ListItemText primary="Once available, you will be able to view your application in the software catalog." />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <Checkbox edge="start" disableRipple checked disabled />
                </ListItemIcon>
                <ListItemText primary="Any modifications to the catlog-info.yaml or other associated files will be automatically reflected in the software catalog, ensuring that it stays up to date." />
              </ListItem>
            </List>
          </Grid>
          <Grid container item xs={12} justifyContent='center' padding={2}>
            <StyledButton variant="contained" onClick={handleClick} disabled={loading}>
              Register Application
            </StyledButton>
          </Grid>
        </StyledCard>
      </Grid>
    </Grid>
  );
};

export default MidgardOnboard;
