import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { configApiRef, useApi } from '@backstage/core-plugin-api';
import { InfoCard } from "@backstage/core-components";

import Button from "@mui/material/Button";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import GitHubIcon from '@mui/icons-material/GitHub';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

import { useOctokitData, HookOptionsType } from "@internal/backstage-plugin-octokit-wrapper";
import type { RootState } from '@internal/redux-react-store';
import { startLoadingPullRequests, updatePullRequests } from '@internal/redux-react-store';
import { CardListItem, CardListItemDate, CardListItemName, CardSubtitle } from "../styled";
import { innersourceGithubLink } from "../homePageData";
import { GithubPullRequestOrIssue } from '@internal/backstage-plugin-homepage-common'

export const CollaborateCard = () => {
  const dispatch = useDispatch();
  const configApi = useApi(configApiRef);
  const owner = 'innersource-nn';
  const type = HookOptionsType.Search;
  const perPage = 8;

  const { data, loading: fetchingInProgress } = useOctokitData({
    owner,
    configApi,
    type,
    perPage,
    searchQuery: "org:innersource-nn+is:open+type:pr"
  });

  const { openPullRequests, isLoading, fetched } = useSelector(
    (state: RootState) => state.pullRequests
  );

  useEffect(() => {
    if (!fetched) {
      dispatch(startLoadingPullRequests());
    }

    if (!fetchingInProgress && data) {
      const transformedData: GithubPullRequestOrIssue[] =
        data.map((pullRequest) => ({
          id: pullRequest.id,
          title: pullRequest.title,
          htmlUrl: pullRequest.html_url,
          createdAt: pullRequest.created_at,
          user: {
            login: pullRequest.user?.login || 'N/A',
          },
        }));

      const newIds = transformedData.map(pr => pr.id);
      const currentIds = openPullRequests ? openPullRequests.map(pr => pr.id) : [];

      if (!fetched || JSON.stringify(newIds) !== JSON.stringify(currentIds)) {
        dispatch(updatePullRequests({ data: transformedData, isLoading: false, fetched: true }));
      }
    }
  }, [dispatch, data, fetchingInProgress, fetched, openPullRequests]);

  return (
    <InfoCard title="Collaborate in Inner-Source-NN">
      <CardContent>
        <Grid container direction="column" spacing={1}>
          <Grid item>
            <CardSubtitle>recently created <b>pull requests</b> in all repositories</CardSubtitle>
          </Grid>
          {isLoading ?
            (
              <Box display="flex" justifyContent="center" width="100%" p={2}>
                <CircularProgress />
              </Box>
            ) : (
              <>
                {!!openPullRequests && openPullRequests.length > 0 ? (
                  openPullRequests.map((pullRequest: GithubPullRequestOrIssue) => (
                    <Grid item key={pullRequest.id}>
                      <CardListItem>
                        <CardListItemDate>{new Date(pullRequest.createdAt).toLocaleDateString()}</CardListItemDate>
                        <CardListItemName>{pullRequest.title} by: <b>{pullRequest.user.login}</b></CardListItemName>
                        <Button
                          component="a"
                          href={pullRequest.htmlUrl}
                          target="_blank"
                          rel="noopener"
                          style={{ minWidth: 'fit-content' }}
                        >
                          <OpenInNewIcon fontSize="small" />
                        </Button>
                      </CardListItem>
                    </Grid>
                  ))
                ) : (
                  <Grid item>
                    <CardListItem data-testid="no-pull-requests-message">No open pull requests found</CardListItem>
                  </Grid>
                )}
              </>
            )
          }
        </Grid>
      </CardContent>
      <CardActions>
        <Box display="flex" justifyContent="center" width="100%">
          <Button
            component="a"
            target="_blank"
            rel="noopener"
            href={innersourceGithubLink}
            startIcon={<GitHubIcon />}
            fullWidth
          >
            <b>novo nordisk innersource</b>
          </Button>
        </Box>
      </CardActions>
    </InfoCard>
  );
};
