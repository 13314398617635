import { ProxiedSignInPage, SignInPage, SignInProviderConfig } from "@backstage/core-components";
import { microsoftAuthApiRef, SignInPageProps } from "@backstage/core-plugin-api";
import React from "react";
import { useApi, configApiRef } from '@backstage/core-plugin-api';

const microsoftProvider: SignInProviderConfig = {
  id: 'microsoft-auth-provider',
  title: 'Microsoft',
  message: 'Sign In using Microsoft Azure AD',
  apiRef: microsoftAuthApiRef,
};

export const SignInPageWrapper = (props: SignInPageProps) => {
  const configApi = useApi(configApiRef);
  const onRuntime = configApi.getOptionalBoolean('ymirConfig.isOnRuntime') ?? false;

  return onRuntime ? (
    <ProxiedSignInPage {...props} provider="oauth2Proxy" />
  ) : (
    <SignInPage {...props} auto provider={microsoftProvider} />
  );
};
