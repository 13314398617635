
import React from "react";

import { EmptyState, InfoCard } from "@backstage/core-components";

import Typography from "@mui/material/Typography";
import Link from '@mui/material/Link';

export const GithubReadmeNoAnnotation: React.FC = () => (
  <InfoCard title="Readme">
    <EmptyState
      missing="field"
      title="Missing GitHub Annotation"
      description={
        <>
          Add <code>github.com/project-slug</code> annotation to display GitHub readme.
          < Typography variant="body2" sx={{ mt: 2 }}>
            For detailed instructions, see the
            <br />
            < Link
              href="/docs/default/system/developer-portal/user_guide/quick-onboard-to-software-catalog/"
              target="_blank"
              rel="noopener"
            >
              Quick Onboard to Software Catalog guide
            </Link>
            .
          </Typography>
        </>
      }
    />
  </InfoCard>
);
