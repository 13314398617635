/*
 * Copyright 2021 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React, { useEffect, useMemo, useState } from 'react';

import { IconComponent } from '@backstage/core-plugin-api';
import { EntityUserFilter } from '@backstage/plugin-catalog-react';
import { useEntityList } from '@backstage/plugin-catalog-react';
import { UserListFilterKind } from '@backstage/plugin-catalog-react';
import { useTranslationRef } from '@backstage/core-plugin-api/alpha';

import { useOwnedEntitiesCount } from './useOwnedEntitiesCount';
import { useAllEntitiesCount } from './useAllEntitiesCount';
import { useStarredEntitiesCount } from './useStarredEntitiesCount';
import { catalogReactTranslationRef } from '../translation';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import SettingsIcon from '@material-ui/icons/Settings';
import StarIcon from '@material-ui/icons/Star';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';

/** @public */
export type CatalogReactUserListPickerClassKey =
  | 'root'
  | 'title'
  | 'listIcon'
  | 'menuItem'
  | 'groupWrapper';

export type CustomButtonGroup = {
  name: string;
  items: {
    id: 'owned' | 'starred' | 'all';
    label: string;
    icon?: IconComponent;
  }[];
};

/** @public */
export type UserListPickerProps = {
  initialFilter?: UserListFilterKind;
  availableFilters?: UserListFilterKind[];
};

const StyledToggleButtonGroup = styled(ToggleButtonGroup)({
  height: '46px',
  marginBottom: '8px',
});

const StyledLabel = styled('label')({
  fontSize: '14px',
  fontWeight: 'bold'
})

/** @public */
export const CustomUserListPicker = (props: UserListPickerProps) => {
  const { initialFilter } = props;
  const {
    updateFilters,
    queryParameters: { user: userParameter },
  } = useEntityList();
  const {
    count: ownedEntitiesCount,
    loading: loadingOwnedEntities,
    filter: ownedEntitiesFilter,
  } = useOwnedEntitiesCount();
  const { count: allCount } = useAllEntitiesCount();
  const {
    count: starredEntitiesCount,
    filter: starredEntitiesFilter,
    loading: loadingStarredEntities,
  } = useStarredEntitiesCount();
  const { t } = useTranslationRef(catalogReactTranslationRef);

  const queryParamUserFilter = useMemo(
    () => [userParameter].flat()[0],
    [userParameter],
  );

  const [selectedUserFilter, setSelectedUserFilter] = useState(
    (queryParamUserFilter as UserListFilterKind) ?? initialFilter,
  );

  const filterCounts = useMemo(() => {
    return {
      all: allCount,
      starred: starredEntitiesCount,
      owned: ownedEntitiesCount,
    };
  }, [starredEntitiesCount, ownedEntitiesCount, allCount]);

  // Set selected user filter on query parameter updates; this happens at initial page load and from
  // external updates to the page location.
  useEffect(() => {
    if (queryParamUserFilter) {
      setSelectedUserFilter(queryParamUserFilter as UserListFilterKind);
    }
  }, [queryParamUserFilter]);

  const loading = loadingOwnedEntities || loadingStarredEntities;

  useEffect(() => {
    if (
      !loading &&
      !!selectedUserFilter &&
      selectedUserFilter !== 'all' &&
      filterCounts[selectedUserFilter] === 0
    ) {
      setSelectedUserFilter('all');
    }
  }, [loading, filterCounts, selectedUserFilter, setSelectedUserFilter]);

  useEffect(() => {
    if (!selectedUserFilter) {
      return;
    }
    if (loading) {
      return;
    }

    const getFilter = () => {
      if (selectedUserFilter === 'owned') {
        return ownedEntitiesFilter;
      }
      if (selectedUserFilter === 'starred') {
        return starredEntitiesFilter;
      }
      return EntityUserFilter.all();
    };

    updateFilters({ user: getFilter() });
  }, [
    selectedUserFilter,
    starredEntitiesFilter,
    ownedEntitiesFilter,
    updateFilters,
    loading,
  ]);

  const [userListFilterOption, setUserListFilterOption] = useState('all');

  const onUserListFilterOptionChange = (
    _event: React.MouseEvent<HTMLElement>,
    newOption: "all" | "starred" | "owned"
  ) => {
    setUserListFilterOption(newOption);
    setSelectedUserFilter(newOption)
  }

  const groups = [
    { id: "all", label: t('userListPicker.orgFilterAllLabel'), icon: undefined },
    { id: "owned", label: t('userListPicker.personalFilter.ownedLabel'), icon: SettingsIcon },
    { id: "starred", label: t('userListPicker.personalFilter.starredLabel'), icon: StarIcon },
  ]

  return (
    <Box>
      <Grid py={1}>
        <StyledLabel id="user-list-picker-label">
          List Type
        </StyledLabel>
      </Grid>
      <Grid>
        <StyledToggleButtonGroup
          value={userListFilterOption}
          onChange={onUserListFilterOptionChange}
          aria-labelledby="user-list-picker-label"
          exclusive
          color="primary"
        >
          {groups.map(group => (
            <ToggleButton
              value={group.id}
              disabled={(filterCounts[group.id] === 0)}
              key={group.id}
            >
              {group.icon ? React.createElement(group.icon) : null} {group.label}
            </ToggleButton>
          ))}
        </StyledToggleButtonGroup>
      </Grid>
    </Box>
  );
};
