import { Entity } from "@backstage/catalog-model";

export const isGithubRepository = (entity: Entity): boolean => {
  const source = entity?.metadata?.annotations?.["backstage.io/source-location"] || "";
  const urlString = source.startsWith("url:") ? source.substring(4) : source;
  try {
    const url = new URL(urlString);
    const allowedHosts = ['github.com', 'www.github.com'];
    return allowedHosts.includes(url.host);
  } catch (e) {
    return false;
  }
};
