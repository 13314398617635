import { useReducer, useEffect, useMemo } from 'react';
import { useApi, discoveryApiRef, fetchApiRef } from '@backstage/core-plugin-api';

import { OctokitClient } from '../api';
import { HookOptions, HookOptionsType } from '../types';
import {
  fetchIssues,
  fetchPullRequests,
  fetchRepos,
  fetchSearch,
  fetchReleases,
  dataFetchReducer
} from '../services/dataFetchFunctions';

export const useOctokitData = (options: HookOptions) => {
  const { owner, repo, configApi, type, searchQuery, perPage } = options;
  const [state, dispatch] = useReducer(dataFetchReducer, {
    data: null,
    loading: true,
    error: null,
  });
  const discoveryApi = useApi(discoveryApiRef);
  const fetchApi = useApi(fetchApiRef);
  const client = useMemo(() => { return new OctokitClient({ discoveryApi, fetchApi }) }, [discoveryApi, fetchApi]);

  useEffect(() => {
    // quirk of linter that's why we need to reconstruct the hookOptions object, so options wouldn't be a dependency
    const hookOptions = { owner, repo, configApi, type, searchQuery, perPage };
    switch (type) {
      case HookOptionsType.Issues:
        fetchIssues(hookOptions, client, dispatch);
        break;
      case HookOptionsType.PullRequests:
        fetchPullRequests(hookOptions, client, dispatch);
        break;
      case HookOptionsType.Repos:
        fetchRepos(hookOptions, client, dispatch);
        break;
      case HookOptionsType.Search:
        fetchSearch(hookOptions, client, dispatch);
        break;
      case HookOptionsType.Releases:
        fetchReleases(hookOptions, client, dispatch);
        break;
      default:
        throw new Error('Invalid type specified');
    }
  }, [configApi, owner, type, repo, searchQuery, perPage, client]);

  return { ...state };
};
