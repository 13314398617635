import React from "react";

import {
  EntityLayout,
  EntityDependsOnComponentsCard,
  EntityDependsOnResourcesCard
} from "@backstage/plugin-catalog";
import { FeatureFlagged } from "@backstage/core-app-api";

import Grid from "@mui/material/Grid";

import { cicdContent } from "../content/CICDContent";
import { overviewContent } from "../content/OverviewContent";
import { techdocsContent } from "../content/TechDocsContent";
import { githubInsightsContent } from "../content/GithubInsightsContent";
import { isGithubRepository } from "../../../../utils/isGithubRepository";
import { novoAccessEntityContent } from "../content/NovoAccessEntityContent";
import { FeatureFlagValue } from "../../../../utils/featureFlags";

export const websiteEntityPage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      {overviewContent}
    </EntityLayout.Route>
    <EntityLayout.Route path="/ci-cd" title="CI/CD">
      {cicdContent}
    </EntityLayout.Route>
    <EntityLayout.Route path="/dependencies" title="Dependencies">
      <Grid container spacing={3} alignItems="stretch">
        <Grid item md={6} xs={12}>
          <EntityDependsOnComponentsCard />
        </Grid>
        <Grid item md={6} xs={12}>
          <EntityDependsOnResourcesCard />
        </Grid>
      </Grid>
    </EntityLayout.Route>
    <EntityLayout.Route path="/docs" title="Docs">
      {techdocsContent}
    </EntityLayout.Route>
    <FeatureFlagged with={FeatureFlagValue.SHOW_NOVOACCESS_CARD}>
      <EntityLayout.Route path="/novoaccess" title="novoAccess">
        {novoAccessEntityContent}
      </EntityLayout.Route>
    </FeatureFlagged>
    <EntityLayout.Route
      path="/github"
      title="GitHub Insights"
      if={isGithubRepository}
    >
      {githubInsightsContent}
    </EntityLayout.Route>
  </EntityLayout>
);
