// TODO: [When Midgard only exisits in Ymir runtimes]

import React from 'react';
import { Provider } from 'react-redux';

import {
  AlertDisplay,
} from '@backstage/core-components';

import { apiDocsPlugin } from '@backstage/plugin-api-docs';
import { catalogPlugin } from '@backstage/plugin-catalog';
import { catalogImportPlugin } from '@backstage/plugin-catalog-import';
import { scaffolderPlugin } from '@backstage/plugin-scaffolder';
import { orgPlugin } from '@backstage/plugin-org';
import { techdocsPlugin } from '@backstage/plugin-techdocs';
import { createApp } from '@backstage/app-defaults';
import { AppRouter } from '@backstage/core-app-api';
import { UnifiedThemeProvider } from '@backstage/theme';
import { shortcutsPlugin } from '@backstage-community/plugin-shortcuts';

import LightIcon from '@mui/icons-material/WbSunny';
import DarkIcon from '@mui/icons-material/NightsStay';

import { SystemInfo } from '@mexl/plugin-envinfo';

import { novoLightTheme } from './utils/themes/novoLightTheme';
import { novoDarkTheme } from './utils/themes/novoDarkTheme';
import { Root } from './components/Root';
import { apis } from './apis';
import { featureFlags } from './utils/featureFlags';
import { routes } from './routes';
import { SignInPageWrapper } from './components/auth/SignInPageWrapper';
import { OAuthRequestDialog} from "@backstage/core-components"
import store from '@internal/redux-react-store';


const app = createApp({
  apis,
  themes: [
    {
      id: 'novo-theme-light',
      title: 'Light Theme',
      variant: 'light',
      icon: <LightIcon />,
      Provider: ({ children }) => (
        <UnifiedThemeProvider theme={novoLightTheme} children={children} />
      ),
    },
    {
      id: 'novo-theme-dark',
      title: 'Dark Theme',
      variant: 'dark',
      icon: <DarkIcon />,
      Provider: ({ children }) => (
        <UnifiedThemeProvider theme={novoDarkTheme} children={children} />
      ),
    },
  ],
  components: {
    SignInPage: props => {
      return (
        <SignInPageWrapper {...props} />
      );
    },
  },
  bindRoutes({ bind }) {
    bind(catalogPlugin.externalRoutes, {
      createComponent: scaffolderPlugin.routes.root,
      viewTechDoc: techdocsPlugin.routes.docRoot,
      createFromTemplate: scaffolderPlugin.routes.selectedTemplate,
    });
    bind(apiDocsPlugin.externalRoutes, {
      registerApi: catalogImportPlugin.routes.importPage,
    });
    bind(scaffolderPlugin.externalRoutes, {
      registerComponent: catalogImportPlugin.routes.importPage,
      viewTechDoc: techdocsPlugin.routes.docRoot,
    });
    bind(orgPlugin.externalRoutes, {
      catalogIndex: catalogPlugin.routes.catalogIndex,
    });
  },
  featureFlags,
  plugins: [shortcutsPlugin],
  });


export default app.createRoot(
  <Provider store={store}>
    <AlertDisplay />
    <OAuthRequestDialog />
    <AppRouter>
      <Root>
        <SystemInfo />
        {routes}
      </Root>
    </AppRouter>
  </Provider>
)
