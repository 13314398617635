import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { configApiRef, useApi } from "@backstage/core-plugin-api";
import { InfoCard } from "@backstage/core-components";

import Button from "@mui/material/Button";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import GitHubIcon from '@mui/icons-material/GitHub';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import FeedbackIcon from '@mui/icons-material/Feedback';

import { useOctokitData, HookOptionsType } from "@internal/backstage-plugin-octokit-wrapper";
import { CardDivider, CardListItem, CardListItemDate, CardListItemName, CardSubtitle } from "../styled";
import { leaveFeedbackLink, reportIssueLink } from "../homePageData";
import type { RootState } from "@internal/redux-react-store";
import { startLoadingReleases, updateReleases } from "@internal/redux-react-store";
import { GithubRelease } from "@internal/backstage-plugin-homepage-common";

export const DiscoverCard = () => {
  const dispatch = useDispatch();
  const configApi = useApi(configApiRef);
  const type = HookOptionsType.Releases;
  const owner = 'innersource-nn';
  const repo = 'midgard';
  const perPage = 1;

  const projects = [
    {
      id: 1,
      name: 'Bifrost',
      description: 'Telemetry data gateway',
      htmlUrl: '../catalog/default/system/bifrost'
    },
    {
      id: 2,
      name: 'Ymir',
      description: 'Control plane manifests',
      htmlUrl: '../catalog/default/system/ymir'
    },
    {
      id: 3,
      name: 'Design System',
      description: 'Digital design resource',
      htmlUrl: '../catalog/default/system/digital-design-system'
    }
  ]

  const { data, loading: fetchingInProgress } = useOctokitData({
    owner,
    configApi,
    repo,
    perPage,
    type
  });

  const { releases, isLoading, fetched } = useSelector(
    (state: RootState) => state.releases
  );

  useEffect(() => {
    if (!fetched) {
      dispatch(startLoadingReleases());
    }

    if (!fetchingInProgress && data) {
      const fetchedReleases: GithubRelease[] =
        data.map((release) => ({
          id: release.id,
          name: release.name ?? 'N/A',
          tagName: release.tag_name,
          publishedAt: release.published_at ?? 'N/A',
          htmlUrl: release.html_url,
        }));

      const newIds = fetchedReleases.map(rel => rel.id);
      const currentIds = releases ? releases.map(rel => rel.id) : [];
      if (JSON.stringify(newIds) !== JSON.stringify(currentIds)) {
        dispatch(startLoadingReleases());
        dispatch(updateReleases({ data: fetchedReleases, isLoading: false, fetched: true }));
      }
    }
  }, [fetchingInProgress, data, releases, fetched, dispatch]);

  return (
    <InfoCard title="Discover Developer Portal">
      <CardContent>
        <Grid container direction="column" spacing={1}>
          <Grid item>
            <CardSubtitle>trending projects from <b>catalog</b></CardSubtitle>
          </Grid>
          {projects.map((project) => (
            <Grid item key={project.id}>
              <CardListItem>
                <CardListItemName><b>{project.name}</b> - {project.description}</CardListItemName>
                <Button
                  component="a"
                  href={project.htmlUrl}
                  target="_blank"
                  rel="noopener"
                  style={{ minWidth: 'fit-content' }}
                >
                  <OpenInNewIcon fontSize="small" />
                </Button>
              </CardListItem>
            </Grid>
          ))}
          <CardDivider />
          <Grid item>
            <CardSubtitle>recent <b>release</b> to production</CardSubtitle>
          </Grid>
          {isLoading ?
            (
              <Box display="flex" justifyContent="center" width="100%" p={2}>
                <CircularProgress />
              </Box>
            ) : (
              <>
                {!!releases && releases.length > 0 ? (
                  releases.map((release) => (
                    <Grid item key={release.id}>
                      <CardListItem>
                        <CardListItemDate>{new Date(release.publishedAt).toLocaleDateString()}</CardListItemDate>
                        <CardListItemName>{release.name}</CardListItemName>
                        <Button
                          component="a"
                          href={release.htmlUrl}
                          target="_blank"
                          rel="noopener"
                          style={{ minWidth: 'fit-content' }}
                        >
                          <OpenInNewIcon fontSize="small" />
                        </Button>
                      </CardListItem>
                    </Grid>
                  ))
                ) : (
                  <Grid item>
                    <CardListItem>No releases found</CardListItem>
                  </Grid>
                )}
              </>
            )
          }
        </Grid>
      </CardContent>
      <CardActions>
        <Box
          display="flex"
          justifyContent="center"
          width="100%"
          flexDirection="column"
        >
          <Button
            component="a"
            target="_blank"
            rel="noopener"
            href={leaveFeedbackLink}
            startIcon={<FeedbackIcon />}
            fullWidth
          >
            <b>feature request or feedback</b>
          </Button>
          <Button
            component="a"
            target="_blank"
            rel="noopener"
            href={reportIssueLink}
            startIcon={<GitHubIcon />}
            fullWidth
          >
            <b>report issue or bug</b>
          </Button>
        </Box>
      </CardActions>
    </InfoCard>
  );
};
