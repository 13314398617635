// originates from https://github.com/backstage/backstage/blob/bef0282748e86328f54404f91da4aa32e4377b19/plugins/catalog-react/src/components/DefaultFilters/DefaultFilters.tsx

/*
 * Copyright 2024 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React from 'react';

import { EntityLifecyclePicker } from '@backstage/plugin-catalog-react';
import {
  EntityOwnerPickerProps,
  EntityOwnerPicker,
} from '@backstage/plugin-catalog-react';
import { EntityProcessingStatusPicker } from '@backstage/plugin-catalog-react';
import { EntityTagPicker } from '@backstage/plugin-catalog-react';

import Grid from '@mui/material/Grid';

/**
 * Props for default filters.
 *
 * @public
 */
export type DefaultFiltersProps = {
  ownerPickerMode?: EntityOwnerPickerProps['mode'];
};

/** @public */
export const CustomFilters = (props: DefaultFiltersProps) => {
  const { ownerPickerMode } = props;

  return (
    <Grid container>
      <Grid item xs={12} md={3} lg={3}>
        <EntityOwnerPicker mode={ownerPickerMode} />
      </Grid>
      <Grid item xs={12} md={3} lg={3}>
        <EntityProcessingStatusPicker />
      </Grid>
      <Grid item xs={12} md={3} lg={3}>
        <EntityTagPicker />
      </Grid>
      <Grid item xs={12} md={3} lg={3}>
        <EntityLifecyclePicker />
      </Grid>
    </Grid>
  );
};
