import React from "react";

import {
  RELATION_PART_OF,
  RELATION_HAS_PART,
  RELATION_API_CONSUMED_BY,
  RELATION_API_PROVIDED_BY,
  RELATION_CONSUMES_API,
  RELATION_PROVIDES_API,
  RELATION_DEPENDENCY_OF,
  RELATION_DEPENDS_ON
} from "@backstage/catalog-model";
import { EntityHasApisCard } from "@backstage/plugin-api-docs";
import {
  EntityLayout,
  EntityAboutCard,
  EntityLinksCard,
  EntityHasComponentsCard,
  EntityHasResourcesCard,
  EntitySwitch
} from "@backstage/plugin-catalog";
import {
  EntityCatalogGraphCard,
  Direction
} from "@backstage/plugin-catalog-graph";

import {
  isGithubInsightsAvailable,
  EntityGithubInsightsLanguagesCard
} from "@roadiehq/backstage-plugin-github-insights";
import Grid from "@mui/material/Grid";
import { FeatureFlagged } from "@backstage/core-app-api";

import { isGithubRepository } from "../../../../utils/isGithubRepository";
import { entityWarningContent } from "../content/EntityWarningContent";
import { linguistEntityContent } from "../content/LinguistEntityContent";
import { novoAccessEntityContent } from "../content/NovoAccessEntityContent";
import { FeatureFlagValue } from "../../../../utils/featureFlags";
import { githubInsightsContent } from "../content/GithubInsightsContent";

export const systemPage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      <Grid container spacing={3} alignItems="stretch">
        {entityWarningContent}
        <Grid container item xs={12} >
          <Grid container item md={6} xs={12} alignContent="flex-start">
            <Grid item xs={12}>
              <EntityAboutCard />
            </Grid>
            <Grid item xs={12}>
              <EntityLinksCard />
            </Grid>
            <Grid item xs={12}>
              <EntityHasApisCard />
            </Grid>
            <Grid item xs={12}>
              <EntitySwitch>
                <EntitySwitch.Case if={isGithubInsightsAvailable}>
                  <EntityGithubInsightsLanguagesCard />
                </EntitySwitch.Case>
                <EntitySwitch.Case>
                  {linguistEntityContent}
                </EntitySwitch.Case>
              </EntitySwitch>
            </Grid>
          </Grid>
          <Grid container item md={6} xs={12} alignContent="flex-start">
            <Grid item xs={12}>
              <EntityCatalogGraphCard height={400} />
            </Grid>
            <Grid item xs={12}>
              <EntityHasComponentsCard />
            </Grid>
            <Grid item xs={12}>
              <EntityHasResourcesCard />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </EntityLayout.Route>
    <EntityLayout.Route path="/diagram" title="Diagram">
      <EntityCatalogGraphCard
        direction={Direction.TOP_BOTTOM}
        title="System Diagram"
        height={700}
        relations={[
          RELATION_PART_OF,
          RELATION_HAS_PART,
          RELATION_API_CONSUMED_BY,
          RELATION_API_PROVIDED_BY,
          RELATION_CONSUMES_API,
          RELATION_PROVIDES_API,
          RELATION_DEPENDENCY_OF,
          RELATION_DEPENDS_ON,
        ]}
        unidirectional={false}
      />
    </EntityLayout.Route>
    <FeatureFlagged with={FeatureFlagValue.SHOW_NOVOACCESS_CARD}>
      <EntityLayout.Route path="/novoaccess" title="novoAccess">
        {novoAccessEntityContent}
      </EntityLayout.Route>
    </FeatureFlagged>
    <EntityLayout.Route
      path="/github"
      title="GitHub Insights"
      if={isGithubRepository}
    >
      {githubInsightsContent}
    </EntityLayout.Route>
  </EntityLayout>
);
