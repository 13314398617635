import { combineReducers } from 'redux';

import discoveryReducer from './slices/discoverySlice';
import pullRequestsReducer from './slices/pullRequestsSlice';
import issuesReducer from './slices/issuesSlice';
import releasesReducer from './slices/releasesSlice';

export default combineReducers({
  discovery: discoveryReducer,
  pullRequests: pullRequestsReducer,
  issues: issuesReducer,
  releases: releasesReducer,
});
