import React from "react";

import { EntityLayout } from "@backstage/plugin-catalog";
import { FeatureFlagged } from "@backstage/core-app-api";

import { isGithubRepository } from "../../../../utils/isGithubRepository";
import { overviewContent } from "../content/OverviewContent";
import { techdocsContent } from "../content/TechDocsContent";
import { githubInsightsContent } from "../content/GithubInsightsContent";
import { novoAccessEntityContent } from "../content/NovoAccessEntityContent";
import { FeatureFlagValue } from "../../../../utils/featureFlags";

export const defaultEntityPage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      {overviewContent}
    </EntityLayout.Route>
    <EntityLayout.Route path="/docs" title="Docs">
      {techdocsContent}
    </EntityLayout.Route>
    <FeatureFlagged with={FeatureFlagValue.SHOW_NOVOACCESS_CARD}>
      <EntityLayout.Route path="/novoaccess" title="novoAccess">
        {novoAccessEntityContent}
      </EntityLayout.Route>
    </FeatureFlagged>
    <EntityLayout.Route
      path="/github"
      title="GitHub Insights"
      if={isGithubRepository}
    >
      {githubInsightsContent}
    </EntityLayout.Route>
  </EntityLayout>
);
