import React from "react";

import Grid from "@mui/material/Grid";

import { EntityNovoAccessCard } from "@internal/backstage-plugin-novoaccess";
import { DescriptionNovoAccessCard } from "@internal/backstage-plugin-novoaccess";

export const novoAccessEntityContent = (
  <Grid container spacing={2}>
    <Grid item xs={12} md={8}>
      <EntityNovoAccessCard />
    </Grid>
    <Grid item xs={12} md={4}>
      <DescriptionNovoAccessCard />
    </Grid>
  </Grid>
);
