import React from "react";

import { EntitySwitch } from "@backstage/plugin-catalog";

import {
  EntityGithubInsightsEnvironmentsCard,
  EntityGithubInsightsComplianceCard,
  isGithubInsightsAvailable,
} from "@roadiehq/backstage-plugin-github-insights";
import {
  EntityGithubPullRequestsOverviewCard,
  EntityGithubPullRequestsTable,
} from "@roadiehq/backstage-plugin-github-pull-requests";
import Grid from "@mui/material/Grid";

import { entityWarningContent } from "./EntityWarningContent";
import { GithubInsightsNoAnnotation } from "../components/GithubInsightsNoAnnotation";

export const githubInsightsContent = (
  <Grid container spacing={3}>
    {entityWarningContent}
    <EntitySwitch>
      <EntitySwitch.Case if={isGithubInsightsAvailable}>
        <Grid item xs={12}>
          <EntityGithubPullRequestsTable />
        </Grid>
        <Grid item lg={4} md={12}>
          <EntityGithubPullRequestsOverviewCard />
        </Grid>
        <Grid item lg={4} md={12} >
          <EntityGithubInsightsComplianceCard />
        </Grid>
        <Grid item lg={4} md={12}>
          <EntityGithubInsightsEnvironmentsCard />
        </Grid>
      </EntitySwitch.Case>
      <EntitySwitch.Case>
        <Grid item xs={12}>
          <GithubInsightsNoAnnotation />
        </Grid>
      </EntitySwitch.Case>
    </EntitySwitch>
  </Grid>
);
