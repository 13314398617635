import React from 'react';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import ExploreIcon from '@mui/icons-material/Explore';

import { infoBoxBulletpoints, infoBoxButtonsData } from './homePageData';
import {
  StyledInfoCard,
  StyledH4,
  StyledButton,
  StyledParagraph,
  StyledInlineButton,
  StyledReactPlayer,
  StyledCallToAction
} from './styled';

const HomePageInfoBox = () => {
  return (
    <StyledInfoCard title="Welcome to Novo Nordisk Developer Portal!">
      <Grid container direction="row">
        <Grid item lg={6} xs={12}>
          <Grid container item px={2} direction="column">
            {infoBoxBulletpoints.length > 0 && (
              <>
                <StyledParagraph>
                  <b>Developer Portal</b> (also called <b>Midgard</b> internally), powered by <b>Backstage</b>, is the ultimate hub for developers, offering:
                </StyledParagraph>
                <ul>
                  {infoBoxBulletpoints.map((bulletpoint) => (
                    <li key={bulletpoint.id}>
                      <StyledH4>
                        {bulletpoint.text}
                        <StyledInlineButton
                          variant="text"
                          href={bulletpoint.button.href}
                        >
                          {bulletpoint.button.label}
                        </StyledInlineButton>
                      </StyledH4>
                    </li>
                  ))}
                </ul>
              </>
            )}
            <Box display="flex" flexDirection="row" alignItems="center">
              <StyledParagraph>
                Get started and
              </StyledParagraph>
              <StyledCallToAction
                variant="outlined"
                href="../discovery"
                size='large'
                startIcon={<ExploreIcon />}
              >
                discover
              </StyledCallToAction>
              <StyledParagraph>
                the portal
              </StyledParagraph>
            </Box>
          </Grid>
          {infoBoxButtonsData?.length > 0 && (
            < Grid
              container item py={6}
              alignItems="center"
              direction="column"
              justifyContent="center"
            >
              <h2>Developer Portal Onboarding</h2>
              <Grid container justifyContent="center" spacing={2}>
                {infoBoxButtonsData.map((button) => (
                  <Grid item key={button.id}>
                    <StyledButton
                      variant="contained"
                      href={button.href}
                    >
                      {button.text}
                    </StyledButton>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          )}
        </Grid>
        <Grid item lg={6} xs={12} container justifyContent="center" alignItems="center">
          <Grid item style={{ position: 'relative', paddingTop: '56.25%', width: '100%' }}>
            <StyledReactPlayer
              url="https://www.youtube.com/watch?v=85TQEpNCaU0"
              controls
              width="100%"
              height="100%"
              style={{ top: 10, left: 10 }}
              light
            />
          </Grid>
        </Grid>
      </Grid>
    </StyledInfoCard >
  );
};

export default HomePageInfoBox;
