import React, { PropsWithChildren } from 'react';

import { UserSettingsSignInAvatar } from '@backstage/plugin-user-settings';
import { SidebarSearchModal } from '@backstage/plugin-search';
import {
  Sidebar,
  sidebarConfig,
  SidebarDivider,
  SidebarGroup,
  SidebarItem,
  SidebarPage,
  SidebarScrollWrapper,
  SidebarSpace,
  useSidebarOpenState,
  Link,
} from '@backstage/core-components';
import { Shortcuts } from '@backstage-community/plugin-shortcuts';

import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import CategoryIcon from '@mui/icons-material/Category';
import HomeIcon from '@mui/icons-material/Home';
import ExtensionIcon from '@mui/icons-material/Extension';
import LibraryBooks from '@mui/icons-material/LibraryBooks';
import CreateComponentIcon from '@mui/icons-material/AddCircleOutline';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import ViewListIcon from '@mui/icons-material/ViewList';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import SettingsIcon from '@mui/icons-material/Settings';
import ExploreIcon from '@mui/icons-material/Explore';
import FiberNewIcon from '@mui/icons-material/FiberNew';
import { styled, useTheme } from '@mui/material/styles';
import Box from "@mui/material/Box";

import DeveloperPortalWatermark from "../../assets/developer-portal__watermark--light.png";
import DeveloperPortalShortWatermark from "../../assets/developer-portal__short-watermark--light.png";
import { useLocalStorage } from '@internal/backstage-plugin-discovery-plugin-react'

const SidebarLogoWrapper = styled('div')({
  height: 3 * sidebarConfig.logoHeight,
  display: 'flex',
  flexFlow: 'row nowrap',
  alignItems: 'center',
  justifyContent: 'center',
  marginBottom: -14,
  width: '100%',
});

const SidebarLogoLink = styled(Link)({
  textAlign: 'center',
  '& .linkOpen': {
    width: sidebarConfig.drawerWidthOpen,
  },
  '& .linkClosed': {
    width: sidebarConfig.drawerWidthClosed,
  },
});

const SidebarLogo = () => {
  const { isOpen } = useSidebarOpenState();

  return (
    <SidebarLogoWrapper >
      <SidebarLogoLink to="/" underline="none" className={isOpen ? 'linkOpen' : 'linkClosed'} aria-label="Home">
        <img
          src={isOpen ? DeveloperPortalWatermark : DeveloperPortalShortWatermark}
          alt='Developer Portal watermark'
          height={isOpen ? '64px' : '52px'}
        />
      </SidebarLogoLink>
    </SidebarLogoWrapper >
  );
}

const DiscoveryIcon = ({ isVisited }) => {
  const theme = useTheme();
  const { isOpen } = useSidebarOpenState();

  return (
    <Box height="100%" display="flex" alignItems="center">
      <ExploreIcon fontSize='small' />
      {!isVisited && !isOpen && (
        <FiberNewIcon
          sx={{
            color: theme.palette.warning.main,
            position: "absolute",
            right: 10,
            bottom: 10
          }}
          fontSize="medium"
        />
      )}
    </Box>
  );
}

export const Root = ({ children }: PropsWithChildren<{}>) => {
  const theme = useTheme();
  const [discoveryPageVisited] = useLocalStorage("discovery-page-visited", false);

  const showDiscoveryNewIcon = discoveryPageVisited
    ? null
    : (
      <Box height="100%" display="flex" alignItems="center">
        <FiberNewIcon sx={{ color: theme.palette.warning.main }} fontSize="large" />
      </Box>
    );

  return (<SidebarPage>
    <Sidebar>
      <SidebarLogo />
      <SidebarGroup label="Search" icon={<SearchIcon />} to="/search">
        <SidebarSearchModal />
      </SidebarGroup>
      <SidebarDivider />
      <SidebarGroup label="Menu" icon={<MenuIcon />}>
        {/* Global nav, not org-specific */}
        <SidebarItem icon={() => <HomeIcon fontSize='small' />} to="/" text="Home" />
        <SidebarItem
          key={`discovery-visited-${discoveryPageVisited}`}
          children={showDiscoveryNewIcon}
          icon={() => <DiscoveryIcon isVisited={discoveryPageVisited} />}
          to="discovery"
          text="Discovery"
        />
        <SidebarItem icon={() => <CategoryIcon fontSize='small' />} to="catalog" text="Catalog" />
        <SidebarItem icon={() => <ExtensionIcon fontSize='small' />} to="api-docs" text="APIs" />
        <SidebarDivider />
        <SidebarItem icon={() => <AppRegistrationIcon fontSize='small' />} to="catalog-import" text="Register Entity" />
        <SidebarItem icon={() => <CreateComponentIcon fontSize='small' />} to="create" text="Run Template" />
        <SidebarItem icon={() => <ViewListIcon fontSize='small' />} to="software-templates" text="Templates List" />
        <SidebarDivider />
        <SidebarItem icon={() => <LibraryBooks fontSize='small' />} to="docs" text="TechDocs" />
        <SidebarItem icon={() => <HelpOutlineIcon fontSize='small' />} to="faq" text="FAQ" />
        <SidebarDivider />
        <Shortcuts allowExternalLinks />
        <SidebarDivider />
        {/* End global nav */}
        <SidebarScrollWrapper />
      </SidebarGroup>
      <SidebarSpace />
      <SidebarDivider />
      <SidebarGroup
        label="Settings"
        icon={<UserSettingsSignInAvatar />}
        to="/settings"
      >
        <SidebarItem icon={() => <SettingsIcon />} to="settings" text="Settings" />
      </SidebarGroup>
    </Sidebar>
    {children}
  </SidebarPage >)
}
