import React from "react";

import { EntityLayout } from "@backstage/plugin-catalog";
import {
  EntityUserProfileCard,
  EntityOwnershipCard
} from "@backstage/plugin-org";

import Grid from "@mui/material/Grid";

import { entityWarningContent } from "../content/EntityWarningContent";

export const userPage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      <Grid container spacing={3}>
        {entityWarningContent}
        <Grid container item xs={12}>
          <Grid container item md={6} xs={12} alignContent="flex-start">
            <Grid item xs={12}>
              <EntityUserProfileCard/>
            </Grid>
          </Grid>
          <Grid container item md={6} xs={12} alignContent="flex-start">
            <Grid item xs={12}>
              <EntityOwnershipCard/>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </EntityLayout.Route>
  </EntityLayout>
);
