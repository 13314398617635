import React from "react";

import {
  EntityAboutCard,
  EntitySwitch,
  EntityLinksCard,
  EntityHasSubcomponentsCard
} from "@backstage/plugin-catalog";
import { EntityCatalogGraphCard } from "@backstage/plugin-catalog-graph";

import {
  isGithubInsightsAvailable,
  EntityGithubInsightsReadmeCard,
  EntityGithubInsightsLanguagesCard,
} from "@roadiehq/backstage-plugin-github-insights";
import Grid from "@mui/material/Grid";

import { entityWarningContent } from "./EntityWarningContent";
import { linguistEntityContent } from "./LinguistEntityContent";
import { isGithubRepository } from "../../../../utils/isGithubRepository";
import { GithubReadmeNoAnnotation } from "../components/GithubReadmeNoAnnotation";

export const overviewContent = (
  <Grid container spacing={3}>
    {entityWarningContent}
    <Grid container item xs={12}>
      <Grid container item md={6} xs={12} alignContent="flex-start">
        <Grid item xs={12}>
          <EntityAboutCard />
        </Grid>
        <EntitySwitch>
          <EntitySwitch.Case if={isGithubRepository}>
            <EntitySwitch>
              <EntitySwitch.Case if={isGithubInsightsAvailable}>
                <Grid item xs={12}>
                  <EntityGithubInsightsReadmeCard maxHeight={500} />
                </Grid>
              </EntitySwitch.Case>
              <EntitySwitch.Case>
                <Grid item xs={12}>
                  <GithubReadmeNoAnnotation />
                </Grid>
              </EntitySwitch.Case>
            </EntitySwitch>
          </EntitySwitch.Case>
        </EntitySwitch>
        <Grid item xs={12}>
          <EntitySwitch>
            <EntitySwitch.Case if={isGithubInsightsAvailable}>
              <EntityGithubInsightsLanguagesCard />
            </EntitySwitch.Case>
            <EntitySwitch.Case>
              {linguistEntityContent}
            </EntitySwitch.Case>
          </EntitySwitch>
        </Grid>
      </Grid>
      <Grid container item md={6} xs={12} alignContent="flex-start">
        <Grid item xs={12}>
          <EntityCatalogGraphCard height={400} />
        </Grid>
        <Grid item xs={12}>
          <EntityHasSubcomponentsCard />
        </Grid>
        <Grid item xs={12}>
          <EntityLinksCard />
        </Grid>
      </Grid>
    </Grid>
  </Grid>
);
