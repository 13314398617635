import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GithubPullRequestOrIssue } from '@internal/backstage-plugin-homepage-common';

export interface IssuesState {
  openIssues: GithubPullRequestOrIssue[];
  isLoading: boolean;
  fetched: boolean;
  error?: any;
}

const initialState: IssuesState = {
  openIssues: [],
  isLoading: false,
  fetched: false,
  error: undefined,
};

const issuesSlice = createSlice({
  name: 'issues',
  initialState,
  reducers: {
    startLoadingIssues(state) {
      state.isLoading = true;
      state.fetched = false;
      state.error = undefined;
    },
    updateIssues(
      state,
      action: PayloadAction<Partial<IssuesState> & { data?: GithubPullRequestOrIssue[] }>
    ) {
      const { data, isLoading, fetched, error } = action.payload;
      if (data) {
        state.openIssues = data;
      }
      if (typeof isLoading !== 'undefined') {
        state.isLoading = isLoading;
      }
      if (typeof fetched !== 'undefined') {
        state.fetched = fetched;
      }
      if (error) {
        state.error = error;
      }
    },
  },
});

export const { startLoadingIssues, updateIssues } = issuesSlice.actions;
export default issuesSlice.reducer;
