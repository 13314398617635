import { OctokitClient } from "../api";
import { HookOptions, Action, State, ActionType } from "../types";

export const dataFetchReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case ActionType.FetchInit:
      return { ...state, loading: true, error: null };
    case ActionType.FetchSuccess:
      return { ...state, loading: false, data: action.payload, error: null };
    case ActionType.FetchFailure:
      return { ...state, loading: false, error: action.payload };
    default:
      throw new Error('Unhandled action type');
  }
};

export const fetchIssues = async (
  options: HookOptions,
  client: OctokitClient,
  dispatch: React.Dispatch<Action>
) => {
  const { repo, owner, perPage } = options;

  if (!repo || !owner) {
    dispatch({ type: ActionType.FetchFailure, payload: new Error('Repository and owner names are required') });
    return;
  }

  try {
    const issuesResult = await client.listIssues({ repo: repo, owner: owner, per_page: perPage });
    if (issuesResult.error) {
      dispatch({ type: ActionType.FetchFailure, payload: new Error(issuesResult.error) });
      return;
    }
    dispatch({ type: ActionType.FetchSuccess, payload: issuesResult });
  } catch (error) {
    dispatch({ type: ActionType.FetchFailure, payload: new Error('Failed to fetch issues') });
  }
};

export const fetchPullRequests = async (
  options: HookOptions,
  client: OctokitClient,
  dispatch: React.Dispatch<Action>
) => {
  const { repo, owner, perPage } = options;
  if (!repo || !owner) {
    dispatch({ type: ActionType.FetchFailure, payload: new Error('Repository and owner names are required') });
    return;
  }

  try {
    const pullRequestsResult = await client.listPullRequests({ repo, owner, per_page: perPage });
    if (pullRequestsResult.error) {
      dispatch({ type: ActionType.FetchFailure, payload: new Error(pullRequestsResult.error) });
      return;
    }
    dispatch({ type: ActionType.FetchSuccess, payload: pullRequestsResult });
  } catch (error) {
    dispatch({ type: ActionType.FetchFailure, payload: new Error('Failed to fetch pull requests') });
  }
};

export const fetchRepos = async (
  options: HookOptions,
  client: OctokitClient,
  dispatch: React.Dispatch<Action>
) => {
  const { owner, perPage } = options
  if (!owner) {
    dispatch({ type: ActionType.FetchFailure, payload: new Error('Owner name is required') });
    return;
  }

  try {
    const reposResult = await client.listRepos({ username: owner, per_page: perPage });
    if (reposResult.error) {
      dispatch({ type: ActionType.FetchFailure, payload: new Error(reposResult.error) });
      return;
    }
    dispatch({ type: ActionType.FetchSuccess, payload: reposResult });
  } catch (error) {
    dispatch({ type: ActionType.FetchFailure, payload: new Error('Failed to fetch repositories') });
  }
};

export const fetchReleases = async (
  options: HookOptions,
  client: OctokitClient,
  dispatch: React.Dispatch<Action>
) => {
  const { repo, owner, perPage } = options
  if (!repo || !owner) {
    dispatch({ type: ActionType.FetchFailure, payload: new Error('Repository and owner names are required') });
    return;
  }

  try {
    const releasesResult = await client.listReleases({ repo, owner, per_page: perPage });
    if (releasesResult.error) {
      dispatch({ type: ActionType.FetchFailure, payload: new Error(releasesResult.error) });
      return;
    }
    dispatch({ type: ActionType.FetchSuccess, payload: releasesResult });
  } catch (error) {
    dispatch({ type: ActionType.FetchFailure, payload: new Error('Failed to fetch releases') });
  }
}

export const fetchSearch = async (
  options: HookOptions,
  client: OctokitClient,
  dispatch: React.Dispatch<Action>
) => {
  const { searchQuery, perPage } = options;
  if (!searchQuery) {
    dispatch({ type: ActionType.FetchFailure, payload: new Error('Search query is required') });
    return;
  }

  try {
    const searchResult = await client.searchIssuesAndPullRequests({ q: searchQuery, per_page: perPage });
    if (searchResult.error) {
      dispatch({ type: ActionType.FetchFailure, payload: new Error(searchResult.error) });
      return;
    }
    dispatch({ type: ActionType.FetchSuccess, payload: searchResult });
  } catch (error) {
    dispatch({ type: ActionType.FetchFailure, payload: new Error('Failed to search for issues and pull requests') });
  }
}
