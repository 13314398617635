import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import { configApiRef, useApi } from "@backstage/core-plugin-api";
import { InfoCard } from "@backstage/core-components";

import Button from "@mui/material/Button";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import GitHubIcon from '@mui/icons-material/GitHub';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

import { useOctokitData, HookOptionsType } from "@internal/backstage-plugin-octokit-wrapper";
import type { RootState } from "@internal/redux-react-store";
import { startLoadingIssues, updateIssues } from "@internal/redux-react-store";
import { CardListItem, CardListItemDate, CardListItemName, CardSubtitle } from "../styled";
import { innersourceGithubLink } from "../homePageData";
import { GithubPullRequestOrIssue } from "@internal/backstage-plugin-homepage-common";

export const EngageCard = () => {
  const dispatch = useDispatch();
  const configApi = useApi(configApiRef);
  const owner = 'innersource-nn';
  const type = HookOptionsType.Search;
  const perPage = 8;

  const { data, loading: fetchingInProgress } = useOctokitData({
    owner,
    configApi,
    type,
    perPage,
    searchQuery: "org:innersource-nn+is:open+type:issue"
  });

  const { openIssues, isLoading, fetched } = useSelector(
    (state: RootState) => state.issues
  );

  const memoizedFetched = useMemo(() => fetched, [fetched]);
  const memoizedOpenIssues = useMemo(() => openIssues, [openIssues]);

  useEffect(() => {
    if (!memoizedFetched) {
      dispatch(startLoadingIssues());
    }

    if (!fetchingInProgress && data) {
      const transformedData: GithubPullRequestOrIssue[] =
        data.map((issue) => ({
          id: issue.id,
          title: issue.title,
          htmlUrl: issue.html_url,
          createdAt: issue.created_at,
          user: {
            login: issue.user?.login || 'N/A',
          },
        }));

     const newIds = transformedData.map(issue => issue.id);
     const currentIds = memoizedOpenIssues ? memoizedOpenIssues.map(issue => issue.id) : [];

     if (JSON.stringify(newIds) !== JSON.stringify(currentIds)) {
       dispatch(updateIssues({ data: transformedData, isLoading: false, fetched: true }));
     }
   }
  }, [dispatch, data, fetchingInProgress, memoizedFetched, memoizedOpenIssues]);


  return (
    <InfoCard title="Engage in Inner-Source-NN">
      <CardContent>
        <Grid container direction="column" spacing={1}>
          <Grid item>
            <CardSubtitle>recently created <b>issues</b> in all repositories</CardSubtitle>
          </Grid>
          {isLoading ?
            (
              <Box display="flex" justifyContent="center" width="100%" p={2}>
                <CircularProgress />
              </Box>
            ) : (
              <>
                {!!openIssues && openIssues.length > 0 ? (
                  openIssues.map((issue) => (
                    <Grid item key={issue.id}>
                      <CardListItem>
                        <CardListItemDate>{new Date(issue.createdAt).toLocaleDateString()}</CardListItemDate>
                        <CardListItemName>"{issue.title}" by: <b>{issue.user.login}</b></CardListItemName>
                        <Button
                          component="a"
                          href={issue.htmlUrl}
                          target="_blank"
                          rel="noopener"
                          style={{ minWidth: 'fit-content' }}
                        >
                          <OpenInNewIcon fontSize="small" />
                        </Button>
                      </CardListItem>
                    </Grid>
                  ))
                ) : (
                  <Grid item>
                    <CardListItem data-testid="no-issues-message">No open issues found</CardListItem>
                  </Grid>
                )}
              </>
            )
          }
        </Grid>
      </CardContent>
      <CardActions>
        <Box display="flex" justifyContent="center" width="100%">
          <Button
            component="a"
            target="_blank"
            rel="noopener"
            href={innersourceGithubLink}
            startIcon={<GitHubIcon />}
            fullWidth
          >
            <b>novo nordisk innersource</b>
          </Button>
        </Box>
      </CardActions>
    </InfoCard>
  );
};
